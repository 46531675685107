import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-channel-list-modal',
  templateUrl: './channel-list-modal.page.html',
  styleUrls: ['./channel-list-modal.page.scss']
})
export class ChannelListModalPage {
  constructor(
    private modalController: ModalController
    // ,private navParams: NavParams
  ) {}
  ionViewWillEnter() {
    // this.myParameter = this.navParams.get("aParameter");
    // this.myOtherParameter = this.navParams.get("otherParameter");
  }
  async closeModal(channel?, direction?) {
    let data;
    if (channel) {
      data = { 'channel': channel,'direction': direction};
    }
    await this.modalController.dismiss(data);
  }

  ngOnInit() {}
}
