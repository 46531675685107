import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
	{ path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule' },
	{ path: 'geolocation-test', loadChildren: './pages/geolocation-test/geolocation-test.module#GeoLocationPageModule' },
	{ path: 'watch-tv', loadChildren: './pages/watch-tv/watch-tv.module#WatchTvPageModule' },
	{ path: 'setup', loadChildren: './pages/setup/setup.module#SetupPageModule' },
	{ path: 'logging', loadChildren: './pages/logging/logging.module#LoggingPageModule' },
	{ path: 'control', loadChildren: './pages/control/control.module#ControlPageModule' },
	{
		path: 'control-antenna',
		loadChildren: './pages/control-antenna/control.antenna.module#ControlAntennaPageModule'
	},
	{ path: 'channels', loadChildren: './pages/channels/channels.module#ChannelsPageModule' },
	{
		path: 'channel-list-modal',
		loadChildren:
			'./pages/channel-list-modal/channel-list-modal.module#ChannelListModalPageModule'
	},
	{ path: 'scan', loadChildren: './pages/scan/scan.module#ScanPageModule' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
