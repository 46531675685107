import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//New Libs
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { QRScanner } from '@ionic-native/qr-scanner/ngx';
 import { BarcodeScanner } from '@ionic-native/barcode-scanner';

import { ChannelListModalPage } from './pages/channel-list-modal/channel-list-modal.page';
import { ChannelListModalPageModule } from './pages/channel-list-modal/channel-list-modal.module';
// import { ScanPageModule } from './pages/scan/scan.module';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [ChannelListModalPage],
	// tslint:disable-next-line: max-line-length
	imports: [
		BrowserModule,
		HttpClientModule,
		HttpModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		ChannelListModalPageModule
		// ScanPageModule
	],
	providers: [
		StatusBar,
		SplashScreen,
		Geolocation,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
